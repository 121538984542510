import { Routes, Route } from "react-router-dom";
import { UserLoginView } from "./authentication/UserLoginView";
import Home from './Why/Home';
import What from './What/What';
import How from './How/How';
import Recruitment from './Recruitment/Recruitment';
import SlidesList from './SlidesList';
import SlideViewer from './SlideViewer';

/* Mock here */
/* import { PrivateRoute } from "./PrivateMockRoute"; */

import { PrivateRoute } from "./PrivateRoute";

export const AppRouter = () => {
    return (
        <Routes>
            <Route path="/login" element={<UserLoginView />} />
            <Route path="/" element={
                <PrivateRoute>
                    <Home />
                </PrivateRoute>
            } />
            <Route path="/what" element={
                <PrivateRoute>
                    <What />
                </PrivateRoute>
            } />
            <Route path="/how" element={
                <PrivateRoute>
                    <How />
                </PrivateRoute>
            } />
            <Route path="/recruitment" element={
                <PrivateRoute>
                    <Recruitment />
                </PrivateRoute>
            } />
            {/* Route to list slides */}
            <Route path="/slides" element={
                <PrivateRoute>
                    <SlidesList />
                </PrivateRoute>
            } />
            {/* Route to view a specific slide */}
            <Route path="/slides/:slideName" element={
                <PrivateRoute>
                    <SlideViewer />
                </PrivateRoute>
            } />
        </Routes>
    );
};
