import React from 'react';
import { Link } from 'react-router-dom';

const SlidesList: React.FC = () => {
    // Manually define the list of slide files
    const slides = [
        'slide1',
        'slide2',
        'slide3',
    ];

    return (
        <div>
            <h1>Available Slides</h1>
            <ul>
                {slides.map((slide, index) => (
                    <li key={index}>
                        <Link to={`/slides/${slide}`}>
                            {slide.replace('-', ' ').toUpperCase()} {/* Format name */}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SlidesList;
